<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-6-27 14:12:18 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-10-19 18:16:21 -->

<template>
    <div class="partners-wrap">
        <div class="partners-content ec-container">
            <slot />
            <div
                v-for="(item, index) in partnersList"
                :key="index"
                class="partner-item"
            >
                <div class="item-title">
                    {{ $t(`borrow.${item.title}`) }}
                </div>
                <div
                    v-if="mounted"
                    class="partners-box"
                    :style="{height: !isMdScreen && item.isScroll ? '.5rem' : ''}"
                >
                    <div v-if="item.isScroll">
                        <div class="cover cover1" />
                        <vue-seamless-scroll
                            :key="scrollKey + index"
                            :data="item.images"
                            :class-option="classOption(item.images.length)"
                            class="swiperWidth"
                        >
                            <div class="box-wrap">
                                <div
                                    v-for="(it, ix) in item.images"
                                    :key="ix"
                                    class="box-item"
                                    :style="{
                                        'background-image': `url(${it})`,
                                        'width': isMdScreen ? item.imagesWidth[ix] + 'rem' : item.imagesWidth[ix] / 2 + 'rem',
                                        'margin-right': index === 2 ? '.6rem' : '',
                                    }"
                                />
                            </div>
                        </vue-seamless-scroll>
                        <div class="cover cover2" />
                    </div>
                    <div
                        v-else
                        class="noScroll-box-wrap"
                    >
                        <div
                            v-for="(it, ix) in item.images"
                            :key="ix"
                            class="noScroll-item"
                        >
                            <div class="title">
                                {{ $t(`borrow.regulatorList.${ix}`) }}
                            </div>
                            <img
                                loading="lazy"
                                :src="it"
                                class="no-scroll-img"
                                :style="{'width': isMdScreen ? item.imagesWidth[ix] : item.imagesWidthSm[ix],}"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import r02 from './img/AFPI.png';
import p03 from './img/ALFAMART.png';
import p01 from './img/BCA.png';
import a07 from './img/BISNISASIA.png';
import p05 from './img/BNC.png';
import p07 from './img/BNI.png';
import p04 from './img/BRI.png';
import p06 from './img/GOPAY.png';
import a04 from './img/HARIANNASIONAL.png';
import a09 from './img/HIGHEND.png';
import p10 from './img/INTRAASIA.png';
import r04 from './img/ISO.png';
import p11 from './img/JAGO.png';
import r01 from './img/KEUANGAN.png';
import r03 from './img/KOMINFO.png';
import r05 from './img/LAPS-SJK.png';
import a08 from './img/MARKET.png';
import a02 from './img/MEDIAINDONESIA.png';
import a03 from './img/MSN.png';
import p08 from './img/OVO.png';
import p09 from './img/PRIVY.png';
import p12 from './img/RELIANCE.png';
import p02 from './img/SEABANK.png';
import p13 from './img/Superbank.png';
import a05 from './img/SWA.png';
import a01 from './img/TRIBUNNEWS.png';
import a11 from './img/WARTAEKONOMI.png';
import a06 from './img/WARTAKOTA.png';
import a10 from './img/WHATSNEW.png';

export default {
    name: 'Partners',

    data() {
        return {
            mounted: false,
            partnersList: [
                {
                    title: 'partners',
                    images: [p01, p02, p03, p04, p05, p06, p07, p08, p09, p10, p11, p12, p13],
                    imagesWidth: [1.31, 1.37, 1.21, 0.94, 0.79, 1.54, 1.16, 1.00, 1.44, 1.08, 1.48, 1.25, 2.24],
                    isScroll: true
                },
                {
                    title: 'regulator',
                    images: [r01, r02, r03, r04, r05],
                    imagesWidth: ['.9rem', '.69rem', '.58rem', '.54rem', '.87rem'],
                    imagesWidthSm: ['.9rem', '.69rem', '.48rem', '.46rem', '.87rem']
                },
                {
                    title: 'asSeenOn',
                    images: [a01, a02, a03, a04, a05, a06, a07, a08, a09, a10, a11],
                    imagesWidth: [1.76, 1.22, 1.05, 2.39, 0.76, 3.42, 2.37, 1.96, 1.48, 0.89, 1.50],
                    isScroll: true
                }
            ],
            isMdScreen: false,
            scrollKey: Date.now()
        };
    },

    computed: {
        classOption() {
            return function (num) {
                return {
                    step: 1,
                    limitMoveNum: num,
                    hoverStop: false,
                    direction: 2,
                    openTouch: true,
                    isSingleRemUnit: true,
                    openWatch: true,
                    screenWidth: ''
                };
            };
        }
    },

    mounted() {
        this.$options.components.vueSeamlessScroll = () => import('vue-seamless-scroll');
        this.mounted = true;
        this.scrollKey = Date.now();
        this.changeScreen();
        window.addEventListener('resize', this.changeScreen);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.changeScreen);
    },

    methods: {
        changeScreen() {
            const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.screenWidth = screenWidth;
            this.isMdScreen = screenWidth > 768;
            if (this.screenWidth !== screenWidth) {
                setTimeout(() => {
                    this.scrollKey = Date.now();
                }, 100);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.partners-wrap {
    width: 100%;
    padding: 1rem 0;
    position: relative;
    background: #fff;

    @media screen and (max-width: $screen-md) {
        padding: 0.4rem 0;
    }

    .partner-item {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        .item-title {
            color: #000;
            font-size: 0.56rem;
            font-weight: 700;
            line-height: 0.8rem;
            margin-bottom: 0.1rem;
        }

        @media screen and (max-width: $screen-md) {
            margin-bottom: 0.4rem;

            .item-title {
                font-size: 0.24rem;
                line-height: 0.24rem;
            }
        }
    }

    .partners-box {
        height: 1rem;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;

        // opacity: 0.7;

        @media screen and (max-width: $screen-md) {
            height: auto;
        }

        .box-wrap {
            display: flex;
            flex-wrap: nowrap;
            width: fit-content;
            height: 1rem;

            .box-item {
                width: 1rem;
                height: auto;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center bottom;
                position: relative;
                flex-shrink: 0;
                margin-right: 0.8rem;
            }

            @media screen and (max-width: $screen-md) {
                height: 0.5rem;

                .box-item {
                    width: 0.5rem;
                    margin-right: 0.4rem;
                }
            }
        }

        .cover {
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 0;
            z-index: 99;

            @media screen and (max-width: $screen-md) {
                width: 0.5rem;
                height: 0.5rem;
            }
        }

        .cover1 {
            left: 0;
            background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .cover2 {
            right: 0;
            background: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .noScroll-box-wrap {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 1rem;

            .noScroll-item {
                width: calc(20% - 0.08rem);
                height: 1rem;
                flex-shrink: 0;
                border-radius: 0.16rem;
                background: #f9f9fa;
                margin-right: 0.1rem;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0 0.2rem;

                &:last-child {
                    margin-right: 0;
                }

                .title {
                    color: #000;
                    font-size: 0.16rem;
                    line-height: 0.24rem;
                    width: 1rem;
                }

                img {
                    height: auto;
                }
            }

            @media screen and (max-width: $screen-md) {
                flex-wrap: wrap;
                height: auto;

                .noScroll-item {
                    width: 100%;
                    height: 0.7rem;
                    margin-right: 0;
                    padding: 0 0.2rem;
                    margin-bottom: 0.1rem;
                    justify-content: space-between;

                    .title {
                        font-size: 0.14rem;
                        line-height: 0.18rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .swiperWidth {
            display: flex;
            width: 100%;
            overflow: hidden;

            ::v-deep {
                div {
                    display: flex !important;
                    overflow: unset !important;
                }
            }
        }
    }
}
</style>
